<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <tiempo-form
          v-if="dbReady"
          :id="null"
          :idparte-trabajo="routeParams.idparte_trabajo"
          :geolocalizacion="geolocalizacion"
          :idtecnico="usuarioIdtecnico"
          :idempleado="usuarioIdempleado"
          :es-desplazamiento="false"
          :por-cronometro="false"
          :has-perm-introducir-tiempos-mas-alla-fecha-actual="hasViewPerm(permissions.tiempos.introducirTiemposMasAllaFechaActual)"
          :has-perm-introducir-otros-tecnicos="hasViewPerm(permissions.tiempos.introducirOtrosTecnicos)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import TiempoForm from '../components/TiempoForm'
import Data from './TiempoAddData'
import { get } from 'vuex-pathify'
import { captureGeolocationCheck, continuarConGeolocalizacion } from '@/utils/maps'
import { toDate, parseHHmm } from '@/utils/date'

export default {
  components: {
    TiempoForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      geolocalizacion: {},
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdempleado: get('usuario/idempleado'),
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir tiempo trabajado'
      this.geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion)
    },
    async submitForm () {
      if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, this.geolocalizacion)) {
        const ffin = parseHHmm(this.formData.hora_fin, toDate(this.formData.finicio))
        await Data.insert(
          this,
          this.formData,
          this.usuarioIdtecnico,
          this.routeParams.idparte_trabajo,
          ffin
        )
        this.$appRouter.go(-1)
      }
    },
  }
}
</script>
