<template>
  <b10-base>
    <v-radio-group
      v-if="tecnicosAsignados.length > 1 && permiteIniciarCronometroTodos && hasPermIntroducirOtrosTecnicos"
      v-model="form.para"
      :disabled="porCronometro"
    >
      <template
        v-for="para in paraOptions"
      >
        <v-radio
          :key="para.id"
          :label="para.descripcion"
          :value="para.id"
        />
      </template>
    </v-radio-group>
    <b10-autocomplete
      v-if="subsistemas.length !== 1"
      v-model="form.idsubsis"
      :items="subsistemas"
      :rules="formRules.idsubsis"
      item-value="subsis.idsubsis"
      item-text="subsis.descripcion"
      label="Subsistema"
      clearable
    />
    <b10-date-time-picker
      v-model="form.finicio"
      title="Inicio"
      :disabled="porCronometro"
      :rules="formRules.finicio"
    />
    <b10-time-picker
      v-model="form.hora_fin"
      title="Hora de fin"
      :disabled="porCronometro"
      :rules="formRules.hora_fin"
    />
    <b10-autocomplete
      v-model="form.idttarea"
      label="Tipo de tarea"
      :items="ttareas"
      item-value="ttarea.idttarea"
      item-text="ttarea.descripcion"
      clearable
      :rules="formRules.idttarea"
    />
    <v-text-field
      v-model.number="form.kilometros"
      label="Kilómetros"
      clearable
      type="number"
      @input="changeKilometros"
    />
    <v-checkbox
      v-model="form.kilometros_facturables"
      label="Kilómetros facturables"
      :messages="messagesKilometrosFacturables"
      :disabled="parte && parte.parte_trabajo && parte.parte_trabajo.idtfacturacion_kilometros !== TFACTURACION_KILOMETROS.ids.kilometrosTecnico"
    />
    <v-checkbox
      v-model="form.es_desplazamiento"
      label="Es desplazamiento"
      @change="changeEsDesplazamiento"
    />
    <b10-autocomplete
      v-model="form.idvehiculo"
      :disabled="!form.es_desplazamiento"
      label="Vehiculo"
      :items="formattedVehiculos"
      item-value="vehiculo.idvehiculo"
      item-text="title"
      clearable
    />
    <v-checkbox
      v-model="form.manodeobrafacturable"
      label="Mano de obra facturable"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <v-text-field
      v-model.number="form.latitud"
      label="Latitud"
      disabled
    />
    <v-text-field
      v-model.number="form.longitud"
      label="Longitud"
      disabled
    />
    <v-btn
      v-show="form.latitud && form.longitud"
      block
      color="secondary"
      dark
      @click.stop="clickIrMapa(form.latitud, form.longitud)"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.mapLocation }}
      </v-icon> Localización
    </v-btn>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './TiempoFormData'
import { CRONOMETRO, TFACTURACION_KILOMETROS, PARAMETRO } from '@/utils/consts'
import { getLatLongURL } from '@/utils/maps'
import _ from '@/utils/lodash'
import { currentDateTime, parseHHmm, toDate, addSeconds } from '@/utils/date'

export default {
  mixins: [formMixin],
  props: {
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
    idparteTrabajo: {
      type: [Number, String],
      default: '',
    },
    idtecnico: {
      type: Number,
      required: true,
    },
    idempleado: {
      type: Number,
      required: true,
    },
    esDesplazamiento: {
      type: Boolean,
    },
    porCronometro: {
      type: Boolean,
      required: true,
    },
    para: {
      type: Number,
      default: 0,
    },
    finicio: {
      type: Date,
      default: function () { return new Date() },
    },
    hasPermIntroducirTiemposMasAllaFechaActual: {
      type: Boolean,
      default: false,
    },
    hasPermIntroducirOtrosTecnicos: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        para: CRONOMETRO.para.soloParaMi,
        idsubsis: null,
        finicio: null,
        hora_fin: null,
        idttarea: null,
        kilometros: 0,
        kilometros_facturables: false,
        es_desplazamiento: false,
        manodeobrafacturable: false,
        observaciones: null,
        latitud: null,
        longitud: null,
        idvehiculo: null,
      },
      formRules: {
        idttarea: [
          v => !!v || 'Campo requerido'
        ],
        idsubsis: [
          v => !!v || 'Campo requerido'
        ],
        finicio: [
          v => !!v || 'Campo requerido'
        ],
        hora_fin: [
          v => this.horasFinValida(v)
        ],
      },
      ttareas: [],
      subsistemas: [],
      manoObraDesplazamientoFacturable: true,
      parte: {},
      tecnicosAsignados: [],
      paraOptions: {
        soloParaMi: {
          descripcion: 'Solo para mí',
          id: CRONOMETRO.para.soloParaMi
        },
        paraTodos: {
          descripcion: 'Para todos los técnicos',
          id: CRONOMETRO.para.paraTodos
        },
      },
      permiteIniciarCronometroTodos: false,
      TFACTURACION_KILOMETROS,
      vehiculos: [],
    }
  },
  computed: {
    messagesKilometrosFacturables () {
      if (this.parte && this.parte.parte_trabajo && this.parte.parte_trabajo.idtfacturacion_kilometros) {
        return `Parte de trabajo con desplazamiento "${_.find(TFACTURACION_KILOMETROS.tipos, { id: this.parte.parte_trabajo.idtfacturacion_kilometros}).descripcion}"`
      } else {
        return ''
      }
    },
    formattedVehiculos () {
      return _.map(this.vehiculos, (item) => {
        item.title = `${item.vehiculo.descripcion} (${item.vehiculo.matricula})`
        return item
      })
    },
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.$set(this.form, 'latitud', newValue.lat)
          this.$set(this.form, 'longitud', newValue.lng)
        }
      },
      deep: true,
    },
  },
  async created () {
    // para uno o todos los técnicos
    this.tecnicosAsignados = await Data.selectTecnicosAsignados(this, this.idparteTrabajo)
    const listaTecnicos = _.map(
      this.tecnicosAsignados, 'empleado.nombre_y_apellido'
    ).join(', ')
    if (this.tecnicosAsignados.length > 1) {
      this.paraOptions.paraTodos.descripcion = `Para todos los técnicos (${listaTecnicos})`
    }
    this.permiteIniciarCronometroTodos = (
      await this.$offline.parametro.valorParametro(PARAMETRO.parametros.INICIAR_DETENER_CRONOMETRO_TODOS)
    ) === '1'
    if (this.porCronometro && this.para) {
      this.$set(this.form, 'para', this.para)
    }
    // inicio
    if (this.porCronometro) {
      this.$set(this.form, 'finicio', this.finicio)
      this.$set(this.form, 'hora_fin', this.$options.filters.shortTime(currentDateTime()))
    } else {
      this.$set(this.form, 'finicio', currentDateTime())
    }
    this.parte = await this.$offline.parteTrabajo.row(this.idparteTrabajo)
    // tipos de tareas que tiene el técnico asignadas, si no tiene ninguna -> todas
    this.ttareas = await Data.selectTtareaDeTecnico(this, this.idtecnico)
    if (this.ttareas.length === 0) {
      this.ttareas = await Data.selectTtarea(this)
    }
    const tecnicoTtarea = await this.$offline.tecnicoTtarea.selectTtareaPrincipal(this.idtecnico)
    if (tecnicoTtarea.length > 0) {
      this.$set(this.form, 'idttarea', tecnicoTtarea[0].ttarea.idttarea)
    }
    // facturación
    this.manoObraDesplazamientoFacturable = (await this.$offline.parametro.valorParametro(
      PARAMETRO.parametros.MANODEOBRA_DESPLAZAMIENTO_FACTURABLE_DEFECTO
    )) === '1'
    this.$set(this.form, 'es_desplazamiento', this.esDesplazamiento)
    if (this.esDesplazamiento) {
      this.$set(this.form, 'manodeobrafacturable', this.manoObraDesplazamientoFacturable)
    } else {
      this.$set(this.form, 'manodeobrafacturable', this.parte.parte_trabajo.manodeobrafacturable)
    }
    this.$set(
      this.form,
      'kilometros_facturables',
      this.parte.parte_trabajo.idtfacturacion_kilometros === TFACTURACION_KILOMETROS.ids.kilometrosTecnico
    )
    // subsistemas
    this.subsistemas = await Data.selectSubsistemas(this, this.idparteTrabajo)
    if (this.subsistemas.length === 1) {
      this.$set(this.form, 'idsubsis', this.subsistemas[0].subsis.idsubsis)
    }
    // vehículos
    this.vehiculos = await Data.selectVehiculo(this)
    this.changeEsDesplazamiento(this.esDesplazamiento)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    changeKilometros (value) {
      if (value > 0) {
        this.$set(this.form, 'es_desplazamiento', true)
        this.$set(this.form, 'manodeobrafacturable', this.manoObraDesplazamientoFacturable)
      } else {
        this.$set(this.form, 'es_desplazamiento', false)
        this.$set(this.form, 'manodeobrafacturable', this.parte.parte_trabajo.manodeobrafacturable)
      }
    },
    horasFinValida (value) {
      if (value) {
        if (this.form.finicio) {
          // sumar 59 segundos a la fecha fin para que permita loguear en el mismo minuto
          const fin = addSeconds(parseHHmm(this.form.hora_fin, toDate(this.form.finicio)), 59)
          if (this.porCronometro) {
            return true
          } else {
            if (this.form.finicio <= fin) {
              if (fin > addSeconds(currentDateTime(), 59)) {
                if (this.hasPermIntroducirTiemposMasAllaFechaActual) {
                  return true
                } else {
                  return 'El tiempo trabajado va mas allá del momento actual'
                }
              } else {
                return true
              }
            } else {
              return 'La hora de fin debe ser posterior a la de inicio'
            }
          }
        } else {
          // sin fecha de inicio no se puede validar
          return true
        }
      } else {
        return 'Campo requerido'
      }
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
    changeEsDesplazamiento(value) {
      if (value) {
        const vehiculoEmpleado = _.find(this.vehiculos, { vehiculo: { idempleado: this.idempleado } })
        if (vehiculoEmpleado) {
          this.$set(this.form, 'idvehiculo', vehiculoEmpleado.vehiculo.idvehiculo)
        }
      } else {
        this.$set(this.form, 'idvehiculo', null)
      }
    }
  }
}
</script>
